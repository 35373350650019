export const items = [
  {
    role: 'student',
    title: 'candidats',
    icon: 'mdi-account',
    group: 'student',
    children: [
      {
        title: 'documents',
        icon: 'mdi-chevron-right',
        to: 'file-manager',
      },
      {
        title: 'link_list',
        icon: 'mdi-chevron-right',
        to: 'link',
      },
    ],
  },

  //   {
  //     subheader: "Apps",
  //     icon: "mdi-apps-box",
  //     divider: true
  //   },

  {
    role: 'author',
    title: 'authors',
    icon: 'mdi-account-edit',
    group: 'author',
    children: [
      {
        title: 'technical_talk',
        icon: 'mdi-chevron-right',
        to: 'fg-oral-questions',
      },
      {
        title: 'critical_incidents',
        icon: 'mdi-chevron-right',
        to: 'ci-oral-questions',
      },
      {
        title: 'Mini Cases',
        icon: 'mdi-chevron-right',
        to: 'mini-cases',
      },
      {
        title: 'documents',
        icon: 'mdi-chevron-right',
        to: 'author-document-list',
      },
    ],
  },
  {
    role: 'qsk',
    title: 'qsk',
    icon: 'mdi-domain',
    group: 'qsk',
    children: [
      {
        title: 'experts_history',
        icon: 'mdi-chevron-right',
        to: 'experts-record',
      },
    ],
  },

  {
    role: 'teacher',
    title: 'experts',
    icon: 'mdi-account-tie',
    group: 'teacher',
    children: [
      {
        title: 'profile',
        icon: 'mdi-chevron-right',
        to: 'expert-profil',
      },
      {
        title: 'documents',
        icon: 'mdi-chevron-right',
        to: 'document-list',
      },
      {
        title: 'availability',
        icon: 'mdi-chevron-right',
        to: 'availability',
      },
      {
        title: 'recusal_request',
        icon: 'mdi-chevron-right',
        to: 'recusal',
      },
    ],
  },
]
